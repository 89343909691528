<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="600px" scrollable transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card class="pa-8">
        <v-row class="d-flex justify-end">
          <v-btn icon color="primary" @click="$emit('close-dialog')">
            <v-icon size="20">
              close
            </v-icon>
          </v-btn>
        </v-row>
        <v-row class="pa-4 d-flex justify-start">
          <v-icon color="primary" size="36">
            lightbulb_outline
          </v-icon>
        </v-row>

        <div class="py-4 v-text-title" v-text="$t('project.academy.users.editStaffModal')" />

        <v-select
          v-model="form.role"
          :items="roleItems"
          :label="$t('common.role')"
          outlined
          dense
        />

        <v-select
          v-if="form.role == 'coach'"
          v-model="form.teamId"
          :label="$t('common.team')"
          :items="teams"
          item-text="suffix"
          item-value="id"
          outlined
          dense
        />

        <v-card-actions class="pa-0 d-flex justify-center">
          <v-btn color="primary" @click="updateRole" v-text="$t('project.academy.users.updateRole')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { email, requiredIf, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'EditStaffDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {
        role: 'collaborator',
        teamId: null,
        emails: [],
      },
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ staffRoles: 'projectUsers/staffRoles' }),
    roleItems({ staffRoles }) {
      return staffRoles.map(v => ({ value: v, text: this.$t(`user.roles.${v}`) }))
    },
    disabledInviteBtn({ form }) {
      return form.emails.length === 0 || this.$v.form.$invalid
    },
  },
  validations() {
    return {
      form: {
        role: { required },
        emails: { $each: { email } },
        teamId: { required: requiredIf(() => this.form.role == 'coach') },
      },
    }
  },
  methods: {
    updateRole() {
      const { organizationId, projectId, userId, staffItemRole: data } = this
      this.runAsync(async () => {
        await this.$store.dispatch('academy/updateStaffRoles', { organizationId, projectId, userId, data })
      })
      this.userId = null
      this.staffItemRole = null
    },
  },
}
</script>
